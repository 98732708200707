/* eslint-disable react/self-closing-comp */
import React from 'react';
import { Helmet } from 'react-helmet';
// import { GTMpageReferrer } from '../../gtm/google-tag-manager';

export default function MetaTags({ data }) {
  // const pageReferrer = useCallback(() => {
  //   GTMpageReferrer(location.pathname);
  // }, [location.pathname]);

  // useEffect(() => {
  //   pageReferrer();
  // }, [pageReferrer]);

  return (
    <Helmet>
      <title>{data.title}</title>
      <meta name="description" content={data.description} />
      <meta charSet="utf-8" />
      <meta property="og:locale" content={data.og_locale} />
      <meta property="og:type" content={data.type} />
      <meta property="og:title" content={data.title} />
      <meta property="og:url" content={data.url} />
      <meta property="og:site_name" content={data.og_site_name} />
      <meta property="og:description" content={data.og_description} />
      <meta
        property="article:modified_time"
        content={data.article_modified_time}
      />
      <meta name="twitter:card" content={data.twitter_card} />

      {/* analytics */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-207539854-1"
      >
      </script>
    </Helmet>
  );
}
